import React from "react";
import { Modal, Button, Form, Input, message, Tabs, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Parse from "parse";

const phoneOptions = [
    { value: '1', label: 'Estados Unidos (+1)' },
    { value: '52', label: 'México (+52)' },
];




const ForgotPassword = ({ open, setOpen }) => {

    const mailReset = () => {
        return (
            <div>
            <p>Ingrese su correo para restablecer la contraseña</p>
                <Form
                    name="forgotPassword"
                    initialValues={{ remember: true }}
                    onFinish={mailSend}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Por favor ingrese su correo' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Correo electrónico" />
                    </Form.Item>
    
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Restablecer contraseña
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
    
    const mailSend = async (values) => {
        try {
            await Parse.User.logOut();
            await Parse.User.requestPasswordReset(values.email);
            message.success('Se ha enviado un correo para recuperar la contraseña');
            setOpen(false);
        } catch (error) {
            console.log(error);
            message.error('Error al enviar el correo');
        }
    }
    
    const phoneReset = () => {
        return (
            <div>
                <p>Ingrese su número de teléfono para restablecer la contraseña</p>
                <Form
                    name="forgotPassword"
                    initialValues={{ remember: true }}
                    onFinish={phoneSend}
                >
                     <Form.Item
                        name="countryCode"
                        rules={[{ required: true, message: 'Por favor ingrese el código de país' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Código de país"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {phoneOptions.map(({ value, label }) => (
                                <Select.Option key={value} value={value}>{label}</Select.Option>
                            ))}
                        </Select>


                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[{ required: true, message: 'Por favor ingrese su número de teléfono' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Número de teléfono" />
                    </Form.Item>
    
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Restablecer contraseña
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
    
    const phoneSend = async (values) => {
        const phoneNumber = values.countryCode + values.phone?.trim();
        try {
            await Parse.User.logOut();
            const result = await Parse.Cloud.run('sendWhatsAppReset', { phoneNumber });
            if (!result.success) {
                message.error(result.message);
                return;
            }
            message.success('Se ha enviado un mensaje de whatsapp para recuperar la contraseña');
            setOpen(false);
        } catch (error) {
            console.log(error);
            message.error('Error al enviar el mensaje de whatsapp');
        }
    }


    return (    
        <Modal
            title="Restablecer contraseña"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
        >
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="WhatsApp" key="1">
                    {phoneReset()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Correo" key="2">
                    {mailReset()}
                </Tabs.TabPane>
            </Tabs>

        </Modal>
    );
}

export default ForgotPassword;
