import React, {useState, useEffect } from 'react';
import { createContext } from 'react';
import axios from 'axios';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState(null);

    const fetchLocation = async () => {
      if (process.env.REACT_APP_MULTICLINIC) return
      console.count('fetchLocation');
      try {
        console.log('Fetching location', process.env.REACT_APP_LOCATION_URL);
        
        const result = await axios.get(process.env.REACT_APP_LOCATION_URL, {
          timeout: 2000 // Timeout in milliseconds
        });

        // Log the status code to check if the request was successful
        // console.log('Result:', result);
      if (result.status === 200) {
        console.log(result.data)
        if (result.data?.sameNetwork) {
          setLocation('Internal');
          // console.log('Internal network');
        }
        else {
          setLocation('External');
          // console.log('External network');
        }
      }
        
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          console.log('Request timeout', error.message);
        } else {
          console.log('Error fetching location', error.message);
        }
        setLocation('External');
      }
    }

    
    return (
        <LocationContext.Provider value={{location, setLocation, fetchLocation}}>
          {children}
        </LocationContext.Provider>
    );
    }