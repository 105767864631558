import { Space, Typography, Button, Card, Modal, message, Checkbox, Input, Row, Col, FloatButton } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import ModalLoadTemplate from './components/ModalLoadTemplate';
import 'react-quill/dist/quill.snow.css';
import Parse from 'parse';
import StudyInfo from '../../components/StudyInfo';
import InterpretationImages from './components/InterpretationImages';
import { UserContext } from '../../context';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoMini from '../../assets/Logo-Mini.png';
import axios from 'axios';
import { ThreeDots, Eye, Download, Save, FileText, Save2, Person } from 'react-bootstrap-icons';

const { Title, Text } = Typography;

const toolbarOptions = {
    toolbar: {
      container: [
        [{'header': [1,2,3,0]}],
        ['bold', 'italic', 'underline', 'strike'], // Basic formatting buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered and bullet lists
        [{ 'script': 'sub' }, { 'script': 'super' }],  // Subscript and superscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],      // Indent and outdent
        [{ 'color': [] }, { 'background': [] }],        // Text and background color buttons
        [{ 'align': [] }],                              // Text alignment buttons
        ['clean']                                       // Clear formatting button
      ],
    },
  };
  

function Interpretation() {
  const [value, setValue] = useState('');
  const [interpretation, setInterpretation] = useState(undefined); // [{}
  const [study, setStudy] = useState({}); 
  const [loading, setLoading] = useState(true);
  const [loadTemplate, setLoadTemplate] = useState(false); // [{}
  const id = useParams().id;
  const [user, setUser] = React.useContext(UserContext);
  const [editing, setEditing] = useState(false);
  const [modalPrint, setModalPrint] = useState(false);
  const navLocation = useLocation();
  const navigate = useNavigate();

  console.log('Interpretation', interpretation)

  useEffect(() => {
    if (!navLocation.state === null) navigate('/login', { state: { route: `/interpretation/${id}` } });
    if (user && !navLocation?.state) navigate('/login', { state: { route: `/interpretation/${id}` } });
  }, [user, navLocation]);


  const ModalImprimir = () => {
    const [printing, setPrinting] = useState(false);
    const [includeImages, setIncludeImages] = useState(true);
    const [includeHeader, setIncludeHeader] = useState(true);
    const [imagesPerRow, setImagesPerRow] = useState(3);
    
    return <Modal
      title="Descargar interpretación"
      open={modalPrint}
      onCancel={() => setModalPrint(false)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
     
       <Space direction='vertical'>
       {study?.incompleteInformation 
        && <Text type='danger' style={{ fontSize: 20 }}>La información del estudio está incompleta, se sugiere completarla antes de descargar</Text> }
        {interpretation?.images && user && <Checkbox checked={includeImages} onChange={(e) => setIncludeImages(e.target.checked)}>Incluir imágenes</Checkbox>}
        {user && <Checkbox checked={includeHeader} onChange={(e) => setIncludeHeader(e.target.checked)}>Incluir encabezado</Checkbox>}
        {user && interpretation?.images && <Input placeholder="Imágenes por columna" value={imagesPerRow} onChange={(e) => setImagesPerRow(e.target.value)} />}
        <Space direction='horizontal'>
          <Button type="primary" onClick={() => print(interpretation.content, includeImages, includeHeader, true, setPrinting, imagesPerRow)} loading={printing}>Descargar</Button>
          <Button onClick={() => print(interpretation.content, includeImages, includeHeader, false, setPrinting, imagesPerRow)} loading={printing}>Abrir en otra pestaña</Button>
        </Space>
      </Space>
    </Modal>
  }

  const removeParseObjects = (object) => {
    if (object instanceof Parse.Object) {
      return {id: object.id, className: object.className};
    }
  
    if (object instanceof Date) {
      return object; // Preserve Date objects
    }
  
    if (Array.isArray(object)) {
      return object.map((item) => {
        if (item instanceof Parse.Object) {
          return {id: item.id, className: item.className};
        } else {
          return removeParseObjects(item);
        }
      });
    }
  
    if (typeof object === 'object' && object !== null) {
      const keys = Object.keys(object);
      const newObject = {};
      keys.forEach((key) => {
        if (object[key] instanceof Parse.Object) {
          newObject[key] = {id: object[key].id, className: object[key].className};
        } else {
          newObject[key] = removeParseObjects(object[key]);
        }
      });
      return newObject;
    }
  
    return object;
  };
  
  const print = async (content, includeImages, includeHeader, download, setPrinting, imagesPerRow) => {
    setPrinting(true);
    let fileName = `Interpretación - ${study?.patient?.fullName||study.patientName||''} - ${moment().format('DD-MM-YYYY')}.pdf`;
    let qrText = `${process.env.REACT_APP_RIS_URL}/study/${study?.internalUUID}`;
    console.log(study)
    let params = { htmlContent: content, includeHeader, images: includeImages ? interpretation.images : [], imagesPerRow, signature: interpretation?.user?.signature, fileName, qrText, study };
    params =  removeParseObjects(params);
    try {
      //REACT_APP_PRINTING_URL
      const response = await Parse.Cloud.run('interpretationPDF',  params);
      console.log('Response', response);
     if (download) {
        const linkSource = response.pdf
        const downloadLink = document.createElement('a');
        downloadLink.href  = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        // Open the result in a new tab as a PDF
        const newWindow = window.open();
        newWindow.document.write(`<iframe src="${response.pdf}" style="width: 100%; height: 100vh;"></iframe>`);
      }

    } catch (error) {
      console.error('Error generating PDF', error);
      message.error('Error generando PDF, asegúrate de permitir las ventanas emergentes');
    }
    setPrinting(false);
  }

  const fetchStudy = async () => {
    // console.log('Fetching study', id);
    const json = await Parse.Cloud.run('getStudy', { studyId: id });
    // console.log('Study', json);
    let interpretation = json?.interpretation?.content
    interpretation = interpretation ? interpretation.replace(/(&nbsp;){2,}/g, '&nbsp;') : '';
    json?.interpretation?.content && setValue(interpretation);
    setStudy(json);
    fetchInterpretation(json);
  }

  const fetchInterpretation = async (study) => {
    const interpretation = await Parse.Cloud.run('getInterpretation', { studyId: study?.objectId });
    console.log('Interpretation', interpretation);
    fetchImages(study, interpretation);
    // console.log('Fetch interpretation', interpretation)
  }

  const fetchImages = async (study, interpretation) => {
    let newInterpretation = interpretation;
    const images = await Parse.Cloud.run('getImages', { studyId: study?.objectId });
    if (images && images.length > 0) newInterpretation.images = images;
    setInterpretation(newInterpretation);
    setLoading(false);
  }


  useEffect(() => {

    fetchStudy();
    
  }, [id]);

  // console.log('User', user);

  const saveInterpretation = async () => {

    let interpretationContent = value;
    interpretationContent = interpretationContent.replace(/(&nbsp;){2,}/g, '&nbsp;');

    try{
      const userId = user?.objectId ? user.objectId : user.id;
      // console.log('User id', userId);
  
      const userPointer = {
        __type: 'Pointer',
        className: '_User',
        objectId: userId
      }

      let interpretationId = ''
      const Interpretations = Parse.Object.extend('Interpretations');
      const interpretationObject = new Interpretations();
      const studyPointer = {
        __type: 'Pointer',
        className: 'Studies',
        objectId: study?.objectId
      }
      interpretation?.objectId && interpretationObject.set('objectId', study?.interpretation.objectId);
      interpretationObject.set('study', studyPointer);
      interpretationObject.set('content', interpretationContent);
      interpretationObject.set('user', userPointer);
      interpretationObject.save().then((result) => {
        interpretationId = result.id;
        
        const Studies = Parse.Object.extend('Studies');
        const studyObject = new Studies();
        studyObject.set('objectId', studyPointer.objectId);
        studyObject.set('interpretation', {
          __type: 'Pointer',
          className: 'Interpretations',
          objectId: interpretationId
        });
        studyObject.set('interpreter', userPointer);
        studyObject.save()
  
  
        const Events = Parse.Object.extend('Events');
        const event = new Events();
        interpretation?.objectId ? event.set('type', 'Interpretation edited') : event.set('type', 'Interpretation created');
        event.set('study', studyPointer);
        event.set('user', userPointer);
        event.save();
  
        message.success('Interpretación guardada');
        setEditing(false);
        fetchStudy();

      }).catch((error) => {
        console.error('Error saving interpretation', error);
        message.error('Error guardando la interpretación');
      })

    } catch (error) {
      console.error('Error saving interpretation', error);
      message.error('Error guardando la interpretación');
    }

    
  }

  const allowEditing = interpretation && interpretation?.user?.objectId === user?.toJSON()?.objectId && moment(interpretation?.createdAt?.iso).diff(moment(), 'hours') < 24;


  const renderButtons = () => {      

     
    if (interpretation && !editing) return <FloatButton 
      type="primary" 
      onClick={() => setModalPrint(true)}
      tooltip='Descargar interpretación'
      icon={<Download />}
    />
  }


      

  return  <Space direction='vertical' style={{padding: 20, paddingTop: 80, paddingBottom: 80, width: '100%'}} >
    <ModalImprimir />
    <ModalLoadTemplate open={loadTemplate} setOpen={setLoadTemplate} setContent={setValue} />
    <Space direction='horizontal' style={{width: '100%', justifyContent: 'flex-start'}}>
      <img src={LogoMini} alt="Logo" style={{ height: 100 }} />
      <Title level={2}>Interpretación</Title>
    </Space>
    <StudyInfo study={study} refresh={() => fetchStudy()} />
        
        <Space direction='vertical' style={{width: '100%', padding: 20}} loading={loading}>
          <Button type="primary" onClick={() =>  window.open(`${process.env.REACT_APP_VIEWER_URL}/viewer?StudyInstanceUIDs=${study?.instanceUUID}`, '_blank') }>Ver imágenes</Button>
          <Card title="Interpretación" style={{ width: '100%' }}>
            {(!interpretation || editing) && <ReactQuill theme="snow" value={value} onChange={setValue} modules={toolbarOptions} />}
            {(interpretation && !editing) && <div dangerouslySetInnerHTML={{ __html: interpretation.content }} />}
            <Space direction='horizontal' style={{width: '100%', justifyContent: 'flex-end', marginTop: 20 }}>
              {(!interpretation || editing) && <Button type='primary' onClick={saveInterpretation}>Guardar</Button>}
              {(!interpretation || editing) && <Button type='primary' onClick={() => setLoadTemplate(true)}>Cargar template</Button>}
              {allowEditing && <Button type='primary' onClick={() => setEditing(true)}>Editar</Button>}
            </Space>
          </Card>
          {!user && <Button type='primary' style={{position: 'fixed', bottom: 50, right: 80}} onClick={() => navigate('/login', { state: { route: `/study/${id}` } })} tooltip='Iniciar sesión' icon={<Person />}>Iniciar sesión</Button>}

          {(editing || interpretation?.images?.length > 0 || !interpretation) && <InterpretationImages study={study} showUpload={(!interpretation || editing)} setInterpretation={setInterpretation} />}
        </Space>
        {renderButtons()}
    </Space>
}

export default Interpretation