import React from 'react'
import { Space, Statistic, Card, Grid } from 'antd'
const { useBreakpoint } = Grid

const StatCards = ({ data, filters }) => {
    const screens = useBreakpoint()
    console.log('StatCards', data)
    if (!data) return null

    console.log('StatCards', data)
    
    if (screens.xs) return <Space direction="horizontal" style={{ width: '70vw', overflowX: 'scroll', justifyContent: 'space-between' }}>
    <Card bordered={false}>
        <Statistic title="Total de estudios" value={data.totalStudies} />
    </Card>
    {(!filters.onlyCompleted && !filters.interpreted) && <Card bordered={false}>
        <Statistic title="Realizados" value={data.completedStudies} />
    </Card>}
    {(!filters.onlyCompleted && !filters.interpreted) && <Card bordered={false}>
        <Statistic title="Pendientes" value={data.pendingStudies} />
    </Card>}
    {(!filters.onlyCompleted && !filters.interpreted) && <Card bordered={false}>
        <Statistic title="Cancelados" value={data.canceledStudies} />
    </Card>}
    <Card bordered={false}>
        <Statistic title="Interpretados" value={data.interpretedStudies} />
    </Card>
    <Card bordered={false}>
            <Statistic title="Información completa" value={100-(data.incompleteInformation/data.totalStudies*100)} precision={2} suffix="%" />
        </Card>
        <Card bordered={false}>
            <Statistic title="Creado desde recepción" value={100-(data.createdFromModality/data.totalStudies*100)} precision={2} suffix="%" />
        </Card>
        <Card bordered={false}>
            <Statistic title="Enviados" value={(data.sentNumber/data.totalStudies*100)} precision={2} suffix="%" />
        </Card>
</Space>

    return <Space direction="horizontal" style={{ justifyContent: 'space-between' }}>
        <Card bordered={false}>
            <Statistic title="Total de estudios" value={data.totalStudies} />
        </Card>
        {(!filters.onlyCompleted && !filters.interpreted) && <Card bordered={false}>
            <Statistic title="Realizados" value={data.completedStudies} />
        </Card>}
        {(!filters.onlyCompleted && !filters.interpreted) && <Card bordered={false}>
            <Statistic title="Pendientes" value={data.pendingStudies} />
        </Card>}
        {(!filters.onlyCompleted && !filters.interpreted) && <Card bordered={false}>
            <Statistic title="Cancelados" value={data.canceledStudies} />
        </Card>}
        <Card bordered={false}>
            <Statistic title="Interpretados" value={data.interpretedStudies} />
        </Card>
        <Card bordered={false}>
            <Statistic title="Información completa" value={100-(data.incompleteInformation/data.totalStudies*100)} precision={2} suffix="%" />
        </Card>
        <Card bordered={false}>
        <Statistic title="Creado desde recepción" value={100-(data.createdFromModality/data.totalStudies*100)} precision={2} suffix="%" />
        </Card>
        {/* <Card bordered={false}>
            <Statistic title="Enviados" value={(data.sentNumber/data.totalStudies*100)} precision={2} suffix="%" />
        </Card> */}
    </Space>
}

export { StatCards }