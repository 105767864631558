import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Layout, Menu, theme, Button, Space, Result, Grid, Skeleton, Modal } from 'antd';
import { theme as antTheme } from 'antd';
import { CaretLeftFilled, LogoutOutlined } from '@ant-design/icons';
import Parse from 'parse';
import { UserContext, LocationContext } from '../../context';
import './Interface.scss';
import LogoMini from '../../assets/Logo-Mini.png';
import LogoPixos from '../../assets/Logo-pixOS.png';
import Pixos from '../../assets/pixOS.png';
import ImageLink from '../../assets/imageLink.png';
import { innerRouter } from '../../routes';
import { ThemeContext } from '../../context';
import LocateConnection from './LocateConnection';
import { Moon, Sun } from 'react-bootstrap-icons';

const { Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const CustomComponent = ({ children, routestate }) => {
  const [collapse, setCollapse] = useState(true);
  const [flipCollapse, setFlipCollapse] = useState(180);
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const [menuRender, setMenuRender] = useState([]);
  const {location, setLocation, fetchLocation} = useContext(LocationContext);
  const [screen, setScreen] = useState('');
  const [loading, setLoading] = useState(true);
  const { theme, setTheme } = useContext(ThemeContext);
  const [render, setRender] = useState('');
  const [showNotification, setShowNotification] = useState(true);
  const screens = useBreakpoint();
  const webLocation = useLocation();
  const {
    token: { colorBgContainer },
  } = antTheme.useToken();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const user = await Parse.User.currentAsync();
        if (!user) {
          console.log("No user is logged in.");
          return;
        }

        await user.fetch();
        console.log("Session is valid.");
      } catch (error) {
        if (error.code === Parse.Error.SESSION_MISSING) {
          console.log("Session is invalid. Logging out...");
          await Parse.User.logOut();
          window.location.href = "/login"; // Redirect to the login page
        } else {
          console.error("Error checking session:", error.message);
          if (error.message === 'Invalid session token') {
            window.location.href = "/login";
          } else if (error.message === 'Session token is expired.') {
            window.location.href = "/login";
          }
        }
      }
    };

    // Set up the interval
    const intervalId = setInterval(checkSession, 15 * 1000); // 1 minute interval

    // Perform an initial check
    checkSession();

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  
  useEffect(() => {
    console.log('WebLocation', webLocation);

    if (!user && !loading) {
      navigate('/login', { state: { route: `/${webLocation ? webLocation.pathname : 'studies'}` } })
    }
    if (user) {
      let menuItems = innerRouter.map((item) => {
        if (item?.roles && item?.roles.includes(user?.get('role')) && item?.label) {
          return item;
        }
      });
      menuItems = menuItems.filter((item) => item !== undefined);
      console.log('MenuItems: ', menuItems);
      setMenuRender(menuItems);
    }

  }, [user, navigate, routestate, loading]);

  useEffect(() => {
    if (user && !user?.get('isActive')) setRender('inactive');
    else if (user && (
      user?.get('role') !== 'Specialist physician' &&
      user?.get('role') !== 'Radiologist' &&
      user?.get('role') !== 'Radiology technician' &&
      user?.get('role') !== 'BOFH' &&
      user?.get('role') !== 'System administrator' &&
      user?.get('role') !== 'Auditor' &&
      user?.get('role') !== 'Employed physician'
    ) && location === 'External') setRender('external');
    else setRender('internal');
  }, [location, user]);

  const logout = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const currentUser = Parse.User.current();
      const userId = currentUser?.id;
      Parse.Cloud.run('removeNotificationsToken', { token, userId });
    }
    Parse.User.logOut();
    setUser(null);
    navigate('/login');
  }

  const Notification = () => {
    return null
    return (
      <Modal
        title="Aviso importante"
        open={showNotification}
        onOk={() => setShowNotification(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>Gracias por su paciencia.</p>
        <p>Los trabajos de mantenimiento en el sistema han concluido.</p>
      </Modal>
    );
  }

  if (!loading && !user) {
    return <></>
  }

  // if (!location || !render) {
  //   fetchLocation();
  //   return (
  //     <Result
  //       title="Obteniendo ubicación"
  //       subTitle="Por favor, espera mientras confirmamos si estás en el hospital."
  //       extra={<Skeleton active />}
  //     />
  //   );
  // }

  // if (render === 'inactive') {
  //   return (
  //     <Result
  //       status="403"
  //       title="403"
  //       subTitle="Lo sentimos, no tienes acceso al sistema."
  //       extra={<Button type="primary" onClick={logout}>Cerrar sesión</Button>}
  //     />
  //   );
  // }

  // if (render === 'external') {
  //     setTheme((prev) => ({ ...prev, algorithm: 'defaultAlgorithm' }));
  //   return (
  //     <Result
  //       status="403"
  //       title="403"
  //       subTitle="Lo sentimos, debes estar en la red del hospital para acceder al sistema."
  //       extra={<Space direction='horizontal' style={{ justifyContent: 'center' }}>
  //         <Button type="primary" onClick={() => window.location.reload()}>Recargar</Button>
  //         <Button type="primary" onClick={logout}>Cerrar sesión</Button>
  //       </Space>}
  //     />
  //   );
  // }

  return (
    <div>
      <Notification />
      <Layout className="container">
        <Sider collapsed={collapse} theme={'light'} className="sider" style={{ height: '100vh', position: 'fixed', left: 0 }}>
          


          {screen !== 'xs' && <div className='collapser'> 
            <CaretLeftFilled style={{ fontSize: 20, cursor: 'pointer', color: theme?.algorithm === 'defaultAlgorithm' ? 'black' : 'white' }}
              rotate={flipCollapse} onClick={() => {
              if (!collapse) {
                setFlipCollapse(180);
                setCollapse(!collapse);
              } else {
                setFlipCollapse(0);
                setCollapse(!collapse);
              }
            }} />
          </div>}
         
              
          <div style={{ color: 'white', justifyContent: 'space-around', flexDirection: 'row', padding: '10px', display: 'flex', alignItems: 'center' }}>
            <img className="logo-img" src={Pixos} alt="Logo"  style={{ margin: '10px', width: collapse ? '50px' : '140px', height: collapse ? '50px' : '150px' }} />
          </div>
          <div style={{ color: 'white', justifyContent: 'space-around', flexDirection: 'row', padding: '10px', display: 'flex', alignItems: 'center' }}>
          {theme?.algorithm === 'defaultAlgorithm' ? 
            <Moon onClick={() => setTheme((prev) => ({ ...prev, algorithm: 'darkAlgorithm' }))} style={{ fontSize: 20, cursor: 'pointer', color: 'black' }} />
           : 
            <Sun onClick={() => setTheme((prev) => ({ ...prev, algorithm: 'defaultAlgorithm' }))} style={{ fontSize: 20, cursor: 'pointer' }} />
          }

          </div>
          {!loading ? (
            <Menu
              onClick={({ key }) => {
                navigate(key, { state: { fromMenu: true } });
              }}
              theme={'light'}
              mode="inline"
              items={menuRender}
              style={{ overflowY: 'auto', overflowX: 'hidden' }}
            />
            
          ) : (
            <Skeleton active />
          )}
                    <LocateConnection collapsed={collapse} />

          <div className='buttoncolapser'>

            <div className='container-logout'>
              <div className='logout'>
                <Button onClick={logout} type='primary' icon={<LogoutOutlined />} size='large' style={{ position: 'fixed', bottom: 20, left: 20 }}>{!collapse && 'Cerrar sesión'}</Button>
              </div>
            </div>
          </div>
        </Sider>
        <Layout style={{ marginLeft: collapse ? 80 : 200 }}>
          {!loading && user ?
            <Content className="content" style={{ height: '100vh', overflowY: 'auto', backgroundColor: colorBgContainer }}>
              <Routes>
                {innerRouter.map(({ path, element, roles }) => {
                 if (roles && roles.includes(user?.get('role'))) {
                   return <Route key={path} path={path} element={element} />;
                  }})}
                {innerRouter.map(({ path, element, roles, children }) => {
                  if (roles && roles.includes(user?.get('role'))) {
                    return children?.map(({ path, element }) => {
                      return <Route key={path} path={path} element={element} />;
                    });
                  }
                })}
              </Routes>
              <Outlet />
            </Content>
            : 
            <Skeleton active />
          }
        </Layout>
      </Layout>
    </div>
  );
};

export {CustomComponent};
