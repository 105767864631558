import React, { useState, useEffect } from 'react';
import { Button, message, FloatButton } from 'antd';
import { Download } from 'react-bootstrap-icons';

const StudyDownloader = ({ study, studies }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(null);

  const downloadStudy = () => {
    setLoading(true);
    message.info('Generando archivo ZIP con las imágenes DICOM');
    console.log('Downloading study', study.orthancUUID);
    console.log('Orthanc URL', process.env.REACT_APP_ORTHANC_URL + '/studies/' + study.orthancUUID + '/archive');
    if (!studies && study.orthancUUID) window.open(`${process.env.REACT_APP_ORTHANC_URL}/studies/${study.orthancUUID}/archive`)
        const uuids = studies?.map(study => study.orthancUUID);
        // Remove duplicated or undefined values
        const uniqueUUIDs = [...new Set(uuids.filter(uuid => uuid))];
        uniqueUUIDs.forEach(uuid => {
            window.open(`${process.env.REACT_APP_ORTHANC_URL}/studies/${uuid}/archive`);
        })
        setLoading(false);
    return 
    fetch(`${process.env.REACT_APP_ORTHANC_URL}/studies/${study.orthancUUID}/archive`)
        .then(res => res.blob())
        .then(
            (result) => {
            setIsLoaded(true);
            // Download the file
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            
            const fileName = study.patient.fullName ? `${study.patient.fullName} - ${study.modality.commonName}.zip` : study.patientName ? `${study.patientName} - ${study.modality.commonName}.zip` : `study.zip`;
            // Create a link to the file
            link.setAttribute('download', fileName);
            link.href = url;
            message.success(<Button type="link" onClick={() => link.click()}>Si la descarga no comienza automáticamente, haz clic aquí</Button>, 10);

            document.body.appendChild(link);

            // Programmatically click on the link to trigger download
            link.click();

            // Cleanup: remove the link and revoke the URL
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            setLoading(false);
            }
        )
        .catch((error) => {
           message.info(<Button type="link" onClick={window.open(`${process.env.REACT_APP_ORTHANC_URL}/studies/${study.orthancUUID}/archive`)}>Si la descarga no comienza automáticamente, haz clic aquí</Button>, 10);
        })
    }   
    
    return <FloatButton type="primary" onClick={downloadStudy} loading={loading} icon={<Download />} tooltip="Descargar imágenes DICOM" />
    
}

export default StudyDownloader;
            