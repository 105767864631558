import React, { useEffect, useState } from 'react';
import { Space, Card, Button, Typography, message, Tag, Row, Col, Modal, Select, Form, Checkbox, Flex } from 'antd';
import UserModal from '../sites/Users/components/UserModal';
import PatientModal from '../sites/Patients/components/PatientModal';
import moment from 'moment';
import {SearchInput} from './SearchInput';
import Parse from 'parse';
const { Title, Text } = Typography;

const ModalStudyInfo = ({study, open, setOpen, refresh, studies}) => {
    const [patient, setPatient] = useState(null);
    const [studyDescription, setStudyDescription] = useState(null);
    const [studyDescriptions, setStudyDescriptions] = useState({});
    const [referrer, setReferrer] = useState(null);
    const [radiologist, setRadiologist] = useState(null);
    const [addUser, setAddUser] = React.useState(false);
    const [addPatient, setAddPatient] = React.useState(false);
    const [protocols, setProtocols] = useState([]);
    const [specialScheduled, setSpecialScheduled] = useState(false);
    const [client, setClient] = useState(null);
    const [modality, setModality] = useState(null);
    const formRef = React.createRef();

    const addStudy = () => {
        const Study = Parse.Object.extend('Studies');
        const newObject = study
        delete newObject.objectId;
        delete newObject.createdAt;
        delete newObject.updatedAt;

        const newStudy = new Study(newObject);
        study.order && newStudy.set('order', {__type: 'Pointer', className: 'Orders', objectId: study.order.objectId});
        study.study && newStudy.set('study', {__type: 'Pointer', className: 'StudyDescriptions', objectId: study.study.objectId});
        study.patient && newStudy.set('patient', {__type: 'Pointer', className: 'Patients', objectId: study.patient.objectId});
        study.referrer && newStudy.set('referrer', {__type: 'Pointer', className: '_User', objectId: study.referrer.objectId});
        study.radiologist && newStudy.set('radiologist', {__type: 'Pointer', className: '_User', objectId: study.radiologist.objectId});
        study.modality && newStudy.set('modality', {__type: 'Pointer', className: 'Modalities', objectId: study.modality.objectId});
        study.interpreter && newStudy.set('interpreter', {__type: 'Pointer', className: '_User', objectId: study.interpreter.objectId});
        study.client && newStudy.set('client', {__type: 'Pointer', className: 'Clients', objectId: client.objectId});
        newStudy.set('createdBy', Parse.User.current());
        newStudy.set('specialScheduled', specialScheduled);

        console.log('Saving study', newStudy)



        newStudy.save().then(() => {
            message.success('Estudio creado correctamente');
            refresh();
            setOpen(false);
        }).catch((error) => {
            console.error('Error while creating study:', error);
            message.error('Error al crear el estudio');
        });
    }

    useEffect(() => {
        const studyDescriptions = {};
        studies?.forEach(study => {
            studyDescriptions[study.objectId] = { objectId: study.study?.objectId, description: study.study?.description }
        })
        setStudyDescriptions(studyDescriptions);
    }, [studies])

    const fetchPatients = async (search) => {
        const query = new Parse.Query('Patients');
        query.matches('fullName', search, 'i');
        const results = await query.find();
        return results.map(result => result.toJSON());
    }

    const fetchStudies = async (search) => {
        const StudyDescriptions = Parse.Object.extend('StudyDescriptions');
        const query = new Parse.Query(StudyDescriptions);
        query.matches('description', search, 'i');
        query.equalTo('modality', {__type: 'Pointer', className: 'Modalities', objectId: modality.objectId});
        const results = await query.find();
        return results.map(result => result.toJSON());
    }

    const fetchReferrers = async (fullName) => {
        console.log('Fetching referrers', fullName)
        const params = { fullName: fullName, role: ['Employed physician', 'Specialist physician'] };
        const results = await Parse.Cloud.run('queryUsers', params);
        return results;
    }

    const fetchRadiologists = async (fullName) => {
        console.log('Fetching radiologists', fullName)
        const params = { fullName: fullName, role: ['Radiologist', 'Radiology physician'] };
        const results = await Parse.Cloud.run('queryUsers', params);
        return results;
    }

    const fetchClients = async (name) => {
        const query = new Parse.Query('Clients');
        query.matches('name', name, 'i');
        const results = await query.find();
        return results.map(result => result.toJSON());
    }

    const fetchModality = async (commonName) => {
        const query = new Parse.Query('Modalities');
        query.matches('commonName', commonName, 'i');
        const results = await query.find();
        return results.map(result => result.toJSON());
    }

    const handlePatientChange = (value, option) => {
        formRef.current?.setFieldsValue({patient: option});
        setPatient(option);
    }

    console.log('study descriptions', studyDescriptions)

    const handleStudyChange = (value, option, study) => {
        formRef.current?.setFieldsValue({studyDescription: option});
        console.log('Study change', value, option)
        setStudyDescriptions({...studyDescriptions, [study.objectId]: { objectId: value.objectId, description: value.description }});
    }

    const handleReferrerChange = (value, option) => {
        formRef.current?.setFieldsValue({referrer: option});
        setReferrer(option);
    }

    const handleRadiologistChange = (value, option) => {
        formRef.current?.setFieldsValue({radiologist: option});
        console.log('Radiologist change', value, option)
        setRadiologist(option);
    }

    const handleClientChange = (value, option) => {
        formRef.current?.setFieldsValue({client: option});
        setClient(option);
    }

    const handleModalityChange = (value, option) => {
        formRef.current?.setFieldsValue({modality: option});
        setModality(option);
    }

    const renderStudies = () => {
        return  <Flex direction='vertical' style={{padding: 20, width: 700, overflowX: 'scroll'}}>
        {studies?.map(study => (
            <Card key={study.objectId}>
            <Form.Item 
            name={['studyDescription', study.objectId]}
            label='Descripción del estudio'
            rules={[{ required: true, message: 'Por favor selecciona un estudio' }]}
        >
            <SearchInput placeholder='Buscar estudio' fetchOptions={fetchStudies} onChange={(value, option) => {
                console.log('Study option', study, value, option)
                handleStudyChange(option, value, study)
            }} text={studyDescriptions[study.objectId]?.description} label='description' />
        </Form.Item>
        {/* <Form.Item 
            name={['protocols', study.objectId]}
            label='Protocolos'
            rules={[{ required: true, message: 'Por favor selecciona al menos un protocolo' }]}
        >
            {study && <Select
            value={protocols}
            style={{ width: '100%' }}
            mode="tags"
            placeholder="Seleccionar protocolos"
            onChange={value => setProtocols(value)}
            options={formRef.current?.getFieldValue(['studyDescription', study.objectId])?.protocols?.map(protocol => ({value: protocol.name, label: protocol.name}))}
        />
        }
        </Form.Item> */} 

    </Card>
        ))}
        <Card title=''>
         <Flex>
         <Button type='primary' 
            onClick={() => addStudy()}
            >Agregar estudio</Button>
        </Flex>
        </Card>
        </Flex>

    }   
    

    const saveStudy = async () => {
        try {
            console.log('Saving studies...');
    
            const toSave = [];
    
            studies.forEach((study) => {
                const studyPointer = {
                    __type: 'Pointer',
                    className: 'StudyDescriptions',
                    objectId: studyDescriptions[study.objectId]?.objectId,
                };
    
                const updateStudy = new Parse.Object('Studies');
                updateStudy.set('objectId', study.objectId); // Only if updating an existing object
                updateStudy.set('study', studyPointer);
                updateStudy.set('description', studyDescriptions[study.objectId]?.description);
                updateStudy.set('patient', {
                    __type: 'Pointer',
                    className: 'Patients',
                    objectId: patient.objectId,
                });
                updateStudy.set('referrer', {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: referrer.objectId,
                });
                radiologist && radiologist.objectId && updateStudy.set('radiologist', {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: radiologist.objectId,
                });
                updateStudy.set('modality', {
                    __type: 'Pointer',
                    className: 'Modalities',
                    objectId: studyDescription?.modality?.id || studyDescription?.modality?.objectId || modality.objectId,
                });
                updateStudy.set('specialScheduled', specialScheduled);
                client && client.objectId && updateStudy.set('client', {
                    __type: 'Pointer',
                    className: 'Clients',
                    objectId: client.objectId,
                });
    
                toSave.push(updateStudy);
            });
    
            console.log('Objects to save:', toSave);
    
            // Save all studies
            await Parse.Object.saveAll(toSave);
            console.log('Studies saved successfully.');
    
            // Create and save events
            const eventsToSave = [];
            studies.forEach(async (study) => {
                const studyPointer = {
                    __type: 'Pointer',
                    className: 'Studies',
                    objectId: study.objectId,
                };
    
                const event = new Parse.Object('Events');
                event.set('user', Parse.User.current());
                event.set('type', 'Study information completed');
                event.set('study', studyPointer);
                eventsToSave.push(event);
                console.log('Event created for study:', study.objectId);
                const result = await Parse.Cloud.run('updateOrthancInfo', { studyId: study.objectId });
                console.log('Orthanc info updated:', result);
            });

            console.log('Events to save:', eventsToSave);
    
            await Parse.Object.saveAll(eventsToSave);
            console.log('Events saved successfully.');
            message.success('Estudios actualizados correctamente');
            setOpen(false);
            refresh();
        } catch (error) {
            console.error('Error during save:', error);
            message.error('Error al actualizar los estudios');
        }
    };
    

    useEffect(() => {
        console.log('Study effetc', study)
        setPatient({value: study?.patient?.objectId, label: study?.patient?.fullName, ...study?.patient});
        setReferrer({value: study?.referrer?.objectId, label: study?.referrer?.fullName, ...study?.referrer});
        setStudyDescription({value: study?.study?.objectId, label: study?.study?.description, ...study?.study});
        setRadiologist({value: study?.radiologist?.objectId, label: study?.radiologist?.fullName, ...study?.radiologist});
        setProtocols(study?.protocols);
        setSpecialScheduled(study?.specialScheduled);
        setModality({value: study?.modality?.objectId, label: study?.modality?.commonName, ...study?.modality});
        setClient({value: study?.client?.objectId, label: study?.client?.name, ...study?.client});

        formRef.current?.setFieldsValue({
            patient: {value: study?.patient?.objectId, label: study?.patient?.fullName, ...study?.patient},
            studyDescription: {value: study?.study?.objectId, label: study?.study?.description, ...study?.study},
            referrer: {value: study?.referrer?.objectId, label: study?.referrer?.fullName, ...study?.referrer},
            radiologist: {value: study?.radiologist?.objectId, label: study?.radiologist?.fullName, ...study?.radiologist},
            protocols: study?.protocols,
            specialScheduled: study?.specialScheduled,
            modality: {value: study?.modality?.objectId, label: study?.modality?.commonName, ...study?.modality},
            client: {value: study?.client?.objectId, label: study?.client?.name, ...study?.client}

        })

    }, [study, open])

    console.log('Study', patient, studyDescription, referrer, radiologist, protocols)

    console.log('Addpatient', addPatient, addUser)

    return (
        <Modal 
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={saveStudy}>
                    Guardar
                </Button>,
            ]}
            width={800}
        >
             <PatientModal patient={addPatient} setPatient={setAddPatient} onSave={(patient) => {
          console.log('On save', patient);
          formRef.current.setFieldsValue({
            patient: {
              value: patient.fullName,
              ...patient
            }
        });
            setAddPatient(false);
          }} />
          

          <UserModal user={addUser} setUser={setAddUser} onAdd={(user) => {
            formRef.current.setFieldsValue({
              clinician: {
                value: user.fullName,
                ...user
              }
            });
            setAddUser(false);
          }} />

            <Space direction='vertical' style={{padding: 20, width: '100%'}} >
                <Title level={2}>Información del estudio</Title>
                <Form 
                    ref={formRef}
                    layout='vertical'
                    >
                    <Form.Item 
                        label='Paciente'
                        name='patient'
                        rules={[{ required: true, message: 'Por favor selecciona un paciente' }]}
                    >
                        <SearchInput placeholder='Buscar paciente' fetchOptions={fetchPatients} onChange={handlePatientChange} text={patient} label='fullName' onAdd={() => setAddPatient(true)} />
                    </Form.Item>
                    <Form.Item 
                        label='Modalidad'
                        name='modality'
                        rules={[{ required: true, message: 'Por favor selecciona una modalidad' }]}
                    >
                        <SearchInput placeholder='Buscar modalidad' fetchOptions={fetchModality} onChange={handleModalityChange} text={modality} label='commonName' />
                    </Form.Item>
                   {renderStudies()}
                    <Form.Item 
                        label='Referidor'
                        name='referrer'
                        rules={[{ required: true, message: 'Por favor selecciona un referidor' }]}
                    >
                        <SearchInput placeholder='Buscar referidor' fetchOptions={fetchReferrers} onChange={handleReferrerChange} text={referrer} label='fullName' onAdd={() => setAddUser(true)} />
                    </Form.Item>
                    <Form.Item 
                        label='Operador'
                        name='radiologist'
                        rules={[{ required: true, message: 'Por favor selecciona un radiólogo' }]}
                    >
                        <SearchInput placeholder='Buscar radiólogo' fetchOptions={fetchRadiologists} onChange={handleRadiologistChange} text={radiologist} label='fullName' />
                    </Form.Item>
                    <Form.Item 
                        label='Cliente'
                        name='client'
                    >
                        <SearchInput placeholder='Buscar cliente' fetchOptions={fetchClients} onChange={handleClientChange} text={client} label='name' />
                    </Form.Item>
                    <Form.Item
                        name='specialScheduled'
                    >
                        <Checkbox onChange={
                            (e) => {
                                console.log('Special scheduled', e.target.checked)
                                setSpecialScheduled(e.target.checked)
                            }}
                        style={{marginBottom: 10}}
                        checked={specialScheduled}
                        >Estudio de guardia</Checkbox>
                    </Form.Item>
                    
                </Form>
            </Space>
        </Modal>
    )
}

export default ModalStudyInfo