import React, { useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { FloatButton,  Space, Table, Tag, Modal,  Form, Input, Select, Button, message, Grid, Card, Radio, Switch } from "antd";
import UserModal from './components/UserModal';
import { UserAddOutlined, MailOutlined, WhatsAppOutlined } from "@ant-design/icons";
import Parse from "parse";
import {query } from './utils/query';
import moment from "moment";
import Title from "antd/es/typography/Title";
const { useBreakpoint } = Grid;

export default function Dashboard () {
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [isSearching, setIsSearching] = React.useState(false);
    const [user, setUser] = React.useState(undefined);
    const [refresh, setRefresh] = React.useState(0);
    const [actualUser ] = useContext(UserContext);
    const screens = useBreakpoint();

    let searchTimeout = null;

    const { Search } = Input;

    const requestPasswordReset = async (email) => {
      if (!email) return message.error('El usuario no tiene un correo electrónico');
      try {
        await Parse.User.requestPasswordReset(email);
        console.log('Password reset request was sent successfully');
        message.success('Se ha enviado un correo electrónico para restablecer la contraseña');
      } catch (error) {
        console.error('Error while requesting password reset: ', error);
        message.error('Ocurrió un error al enviar el correo electrónico');
      }
    };

    const requestEmailVerification = async (email) => {
      if (!email) return message.error('El usuario no tiene un correo electrónico');
      try {
        await Parse.User.requestEmailVerification(email);
        console.log('Email verification request was sent successfully');
        message.success('Se ha enviado un correo electrónico para verificar la dirección de correo electrónico');
      } catch (error) {
        console.error('Error while requesting email verification: ', error);
        message.error('Ocurrió un error al enviar el correo electrónico');
      }
    }

    const sendWhatsAppVerification = async (user) => {
      console.log('Sending WhatsApp verification to', user.phoneNumber);
      if (!user.phoneNumber) return message.error('El usuario no tiene un número de teléfono');
      try {
        const response = Parse.Cloud.run('sendWhatsAppVerification', { userId: user.objectId });
        console.log('WhatsApp verification request was sent successfully', response);
        message.success('Se ha enviado un mensaje de WhatsApp para verificar el número de teléfono');
      } catch (error) {
        console.error('Error while sending WhatsApp verification: ', error);
        message.error('Ocurrió un error al enviar el mensaje de WhatsApp');
      }
    }
    



    useEffect(() => {
        queryUsers();
    }, [refresh]);

    const queryUsers = async (value) => {
        const results = await query(value);
        console.log('Users', results);
        setUsers(results);
        setLoading(false);
    }
    

    const parseRole = (role) => {
      let message = ''
      switch(role) {
          case 'Employed physician':
              message = 'Médico de guardia'
              break;
          case 'Radiology physician':
              message = 'Médico radiólogo'
              break;
          case 'Specialist physician':
              message = 'Médico especialista'
              break;
          case 'Administrative':
              message = 'Administrativo'
              break;
          case 'Radiologist':
              message = 'Técnico radiólogo'
              break;
          case 'Auditor':
              message = 'Auditor'
              break;
          case 'BOFH':
              message = 'BOFH'
              break;
          case 'RRHH':
              message = 'Recursos Humanos'
              break;
          case 'System administrator':
              message = 'Administrador del sistema'
              break;
          default:
              message = 'Rol desconocido'
      }
      return message
  }

  const setActive = async (user, isActive) => {
    const role = actualUser?.get('role');
    if (role !== 'System administrator' && role !== 'BOFH' && role !== 'RRHH') {
      return message.error('No tienes permisos para realizar esta acción');
    }
    if (user.objectId === actualUser.id) {
      return message.error('No puedes desactivar tu propia cuenta');
    }
    console.log('Setting user active status', user, isActive);
    const params = { userId: user.objectId, isActive };
    try {
      const response = await Parse.Cloud.run('setUserActive', params);
      console.log('User active response', response);
      message.success('Usuario actualizado correctamente');
      setRefresh(refresh + 1);
    }
    catch (error) {
      console.error('Error while updating user active status: ', error);
      message.error('Ocurrió un error al actualizar el estado del usuario');
    }
  }

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'fullName',
        key: 'user',
        render: (name, record) => <a onClick={() => setUser({...record, editing: true})}>{name}</a>
      },
      {
        title: 'Rol',
        dataIndex: 'role',
        key: 'role',
        render: (role) => parseRole(role)
      },
     
      {
        title: 'Sexo', 
        dataIndex: 'gender',
        key: 'gender',
      },
      {
        title: 'Teléfono',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (phone) => phone?.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5')
      },
      {
        title: 'Correo electrónico',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: '',
        key: 'actions',
        render: (user) => (
            user?.phoneNumber ? user.phoneNumberVerified ? <Button style={{ backgroundColor: '#25D366', color: 'white' }} onClick={() => sendWhatsAppVerification(user)} ><WhatsAppOutlined />Restablecer contraseña</Button> : <Button style={{ backgroundColor: '#25D366', color: 'white' }} onClick={() => sendWhatsAppVerification(user)} ><WhatsAppOutlined />Verificar WhatsApp</Button> : <p>El usuario no tiene un número de teléfono</p>
        )
      },
      {
        title: '',
        key: 'actions',
        render: (user) => (
            user?.email ? user.emailVerified ? <Button type="primary" onClick={() => requestPasswordReset(user.email)} ><MailOutlined />Restablecer contraseña</Button> : <Button type="primary" onClick={() => requestEmailVerification(user.email)} ><MailOutlined />Verificar correo</Button> : <p>El usuario no tiene un correo electrónico</p>
        )
      },
      {
        title: 'Estado',
        key: 'isActive',
        dataIndex: 'isActive',
        render: (isActive, user) => (
          <Switch checked={isActive} onChange={(active) => setActive(user, active)} disabled={user.objectId === actualUser?.get('objectId')} />
        )
      }
      
    ];

    const Filters = () =>   <Search 
    placeholder="Buscar Usuario"
    onChange={(e) => {
      setIsSearching(true);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        queryUsers(e.target.value);
      }, 1000);
    }}
    style={{ width: 200, float: 'right' }}
    loading={isSearching}
    />

    const UsersList = () => <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
      {users.length > 0 && users.map((user) => (
        <Card key={user.uuid} title={<Space direction="horizontal">{user.fullName}</Space>} style={{ width: '100%' }} onClick={() => setUser({...user, editing: true})} hoverable>
          <Space direction="vertical">
            <p>Rol: {parseRole(user.role)}</p>
            <p>Fecha de nacimiento: {moment(user.birthday?.iso).format('DD/MM/YYYY')}</p>
            <p>Sexo: {user.gender}</p>
            <p>Teléfono: {user.phoneNumber?.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5')}</p>
            <p>Correo electrónico: {user.email}</p>
          </Space>
        </Card>
      ))}
    </Space>

    if (screens.xs) {
      return (
          <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
              <FloatButton icon={<UserAddOutlined />} onClick={() => setUser({})} tooltip="Agregar usuario" 
              type="primary" />
              <UserModal user={user} setUser={setUser} refresh={queryUsers} />
                <Space size="large" direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Title level={4}>Usuarios</Title>
                  <Filters />
                </Space>
                <UsersList />
          </Space>
      );
    }

    return (
        <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
            <FloatButton icon={<UserAddOutlined />} onClick={() => setUser({})} tooltip="Agregar usuario" 
            type="primary" />
            <UserModal user={user} setUser={setUser} refresh={queryUsers} />
              <Space size="large" direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Title level={1}>Usuarios</Title>
                <Filters />
              </Space>
              <Table columns={columns} dataSource={users} loading={loading} locale={{ emptyText: 'No hay Estudios'  }} />
        </Space>
    );
}