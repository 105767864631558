import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { Space, Card, Button, Typography, Divider, Tag, Row, Col, Modal, Grid } from 'antd';
import ModalStudyInfo from './ModalStudyInfo';
import { UserContext } from '../context';
import moment from 'moment';
import Parse from 'parse';
const { useBreakpoint } = Grid;

const { Title, Text } = Typography;
const StudyInfo = ({study, refresh, studies}) => {
    const [editing, setEditing] = useState(false);
    const [user] = useContext(UserContext);
    const screens = useBreakpoint();
    const location = useLocation();
    const navigate = useNavigate();

    const FullfillStudy = () => {
        const role = user?.get('role');
        if (study?.incompleteInformation) {
            if (role === 'Administrative' || role === 'BOFH' || role === 'Auditor' || role === 'Radiologist') {
            return <Button type="primary" onClick={() => setEditing(true)} style={{ backgroundColor: 'orange', borderColor: '#FFD700', margin: 10 }}>Completar información</Button>
            } else {
                return <Text style={{fontSize: 20, color: 'gray', padding: 20}}>Solicita al personal administrativo que se complete la información </Text>
            }
        } else if (study?.status !== 'Cancelado') {
            if (role === 'Administrative' || role === 'BOFH' || role === 'Auditor' || role === 'Radiologist') {
                return <Button type="primary" onClick={() => setEditing(true)} style={{  margin: 10 }}>Editar información</Button>
            }
        }
    }


    return (
        <Space direction='vertical' style={{padding: 20, width: '100%'}} >
            <ModalStudyInfo study={study} open={editing} setOpen={setEditing} refresh={refresh} studies={studies} />
                <Row gutter={[16, 16]}>
                    <Col span={screens.xs ? 24 : 6}>
                        <Card style={{ width: '100%' }} title="Estudio">
                            <Space direction='vertical'>
                                <Text style={{fontSize: 20}}>{study?.modality?.commonName ? study?.modality?.commonName : 'Estudio no definido'}</Text>
                                <Text>{moment(study?.date).format('DD/MM/YYYY')}</Text>
                                {location.pathname.includes('/study') ? <Text>{study?.accessionNumber}</Text> : <Button type='link' onClick={() => navigate(`/study/${study?.internalUUID}`, { state: { fromMenu: true }})} style={{ padding: 0, margin: 0}}><Text>{study?.accessionNumber}</Text></Button>}
                            </Space>
                        </Card>
                    </Col>
                    <Col span={screens.xs ? 24 : 6}>
                        <Card title="Paciente" style={{ width: '100%' }}>
                            <Space direction='vertical'>
                                {study?.patient?.fullName ? <a href={`/patient/${study.patient?.uuid}`}>{study?.patient?.fullName}</a> : <Text style={{fontSize: 20, color: 'red'}}>{study?.patientName}</Text>}
                                <Text>{study?.patient ? study?.patient?.gender : study?.patientGender ? study?.patientGender : 'Género no especificado'}</Text>
                                <Text>{study?.patient ? moment(study?.patient?.birthday.iso).format('DD/MM/YYYY') : study.patientBirthday ? moment(study?.patientBirthday).format('DD/MM/YYYY') : 'Fecha de nacimiento no especificada'}</Text>
                                {user && <Text>{study?.patient?.phoneNumber}</Text>}
                            </Space>
                        </Card>
                    </Col>
                    {study?.referrer &&<Col span={screens.xs ? 24 : 6}>
                         <Card title="Referidor" style={{ width: '100%' }}>
                            <Space direction='vertical'>
                            {study?.referrer?<Text style={{fontSize: 20}}>{study?.referrer?.fullName}</Text>:<Text style={{fontSize: 20, color: 'red'}}>{study?.referingPhysicianName}</Text>}
                                {user && <Text>{study?.referrer?.phoneNumber}</Text>}
                            </Space>
                        </Card>
                    </Col>}
                    <Col span={screens.xs ? 24 : 6}>
                    {study?.interpreter && <Card title="Intérprete" style={{ width: '100%' }}>
                            <Space direction='vertical'>
                            <Text style={{fontSize: 20}}>{study?.interpreter?.fullName}</Text>
                                {user && <Text>{study?.interpreter?.phoneNumber}</Text>}
                            </Space>
                        </Card>}
                        
                    </Col>
                    <Col span={screens.xs ? 24 : 24}>
                        <FullfillStudy />
                    </Col>
                </Row>
                <Divider />
                <Space direction='horizontal' style={{width: '100%', justifyContent: 'space-between'}}>
                
                </Space>
        </Space>
    )
}

export default StudyInfo
