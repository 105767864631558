import React, { useState, useEffect, useContext } from 'react';
import {Card, Button, Space, Typography, Divider, Popover, message, Modal, Tabs, Select, Input, Checkbox, Form, Row, Col} from 'antd';
import Parse from 'parse';

const saveNotes = async (study, notes) => {
    try {
        const Studies = Parse.Object.extend('Studies');
        const studyObject = new Studies();
        studyObject.set('objectId', study.objectId);
        studyObject.set('notes', notes);
        await studyObject.save();
        message.success('Observaciones guardadas');
    } catch (error) {
        message.error(error.message);
    }
}

const allowSeeNotes = (user) => {
    return user?.get('role') === 'BOFH' || 
    user?.get('role') === 'Administrative' ||
    user?.get('role') === 'Radiologist' ||
    user?.get('role') === 'Auditor' ||
    user?.get('role') === 'System administrator' ||
    user?.get('role') === 'Radiology physician' ||
    user?.get('role') === 'Employed physician';
}

const allowEditNotes = (user) => {
    return user?.get('role') === 'BOFH' || 
    user?.get('role') === 'Radiology physician' ||
    user?.get('role') === 'Radiologist';
}

const StudyNotes = ({study, user}) => {
    const [notes, setNotes] = useState(study?.notes);

   if (!study || !user) return null;
   if (allowSeeNotes(user)) return (
        <Card title="Observaciones" style={{ width: '100%', overflowY: 'scroll', maxHeight: 400, padding: 20, margin: 20 }}>
            <Input.TextArea
                value={study.notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Observaciones"
                autoSize={{ minRows: 3, maxRows: 5 }}
                disabled={!allowEditNotes(user)}
            />
            {allowEditNotes(user) && <Button type="primary" onClick={() => saveNotes(study, notes)} style={{marginTop: 10}}>Guardar observaciones</Button>}
        </Card>
    )
}

export default StudyNotes;