import React, {useState, useEffect, } from 'react';
import { Flex, Button, Input, message, Card, Form } from 'antd';
import Parse from 'parse';
import pixosBanner from '../../assets/pixOS-Banner.png';
import TextArea from 'antd/es/input/TextArea';

const verifyWhatsapp = async (uuid, token, password) => {
    console.log('Verifying WhatsApp code', uuid, token, password);

    try {
        const response = await Parse.Cloud.run('verifyWhatsAppCode', { userUuid: uuid, token, password });
        console.log('WhatsApp code verification response:', response);
        if (!response.success) {
            switch (response.status) {
                case 404:
                    console.error('User not found');
                    message.error('Usuario no encontrado');
                    break;
                case 405:   
                    console.error('Invalid verification code');
                    message.error('El código de verificación es inválido o ha expirado');
                    break;
                case 500:
                    console.error('Error while verifying WhatsApp code');
                    message.error('Ocurrió un error al verificar el código de WhatsApp');
                    break;
                default:
                    console.error('Error while verifying WhatsApp code:', response.status);
                    message.error('Ocurrió un error al verificar el código de WhatsApp');
                    break;
            }
            return;
        }
        console.log('WhatsApp code verification was successful', response);
        message.success('La verificación y cambio de contraseña fue exitosa');
        window.location.href = '/login';
    }
    catch (error) {
        console.error('Error while verifying WhatsApp code:', error);
        message.error('Ocurrió un error al verificar el código de WhatsApp');
    }
}

const VerifyWhatsapp = () => {
    // Get query parameters from URL
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    const token = urlParams.get('token');
    const [password, setPassword] = useState('');
    const [verificationPassword, setVerificationPassword] = useState('');

    useEffect(() => {
        try {
            Parse.User.logOut();
        } catch (error) {
            console.error('Error while logging out:', error);
        }
    }, []);

    return (
        <Flex vertical justify="center" align="center" style={{ height: '100vh', padding: '20px' }}>
            <img src={pixosBanner} alt="pixOS" style={{ width: '100%', maxWidth: '300px', paddingBottom: '20px' }} />
            <Card title="Verificando WhatsApp" style={{ width: '100%', maxWidth: '300px' }}>
            
            <Flex vertical gap="10px">
            <p>Ingresa tu nueva contraseña para verificar tu número de WhatsApp</p>
            <Card.Meta title="Elige tu nueva contraseña" />
            <Form
                name="verifyWhatsapp"
                initialValues={{ remember: true }}
                onFinish={() => verifyWhatsapp(uuid, token, password)}
            >
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Ingresa tu contraseña' }, 
                        { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]}
                >
                <Input.Password
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                </Form.Item>
                <Form.Item
                    name="verificationPassword"
                    rules={[{ required: true, message: 'Confirma tu contraseña' },
                        { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' },
                        { validator: (rule, value) => {
                            if (value !== password) {
                                return Promise.reject('Las contraseñas no coinciden');
                            }
                            return Promise.resolve();
                        }}
                    ]}
                >
                <Input.Password
                    placeholder="Confirmar contraseña"
                    value={verificationPassword}
                    onChange={(e) => setVerificationPassword(e.target.value)}
                />
                </Form.Item>
                <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Verificar
                </Button>
                </Form.Item>
            </Form>
            </Flex>
            </Card>
        </Flex>
    );
}

export default VerifyWhatsapp;